import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';

import {
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  Button,
  LinearProgress
} from '@material-ui/core';

import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from '@material-ui/core/styles';
import UniversalActions from 'stores/otc-admin-merchant/universal/actions';
import Vaildator from 'utils/validator';

const CustomTextField = withStyles((theme: Theme) => ({
  root: {
    width: 287,
  },
  input: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}))((props: any) => {
  const { classes, ...params } = props;

  return (<TextField
    variant='outlined' classes={{ root: classes.root }} {...params} InputProps={{
      ...props.InputProps,
      classes: {
        input: classes.input,
      },
    }}
  />)
});

const Label = withStyles((theme: Theme) => ({
  root: {
    minWidth: 168,
    textAlign: 'right',
    color: theme.palette.secondary.main,
  },
}))(Box);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleArea: {
      borderBottom: '1px solid #efefef',
      color: '#2e2e2e',
      fontWeight: 500,
      fontSize: '1rem'
    },
    fieldArea: {
      display: 'flex',
      alignItems: 'center',
    },
    saveBtn: {
      paddingLeft: 46,
      paddingRight: 46,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
    },
  })
);

const PaymentMethod = (props: any) => {
  const {
    intl,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>({
    'cny-default-amount': 0,
    'limit-ratio': 0,
  });

  // const { data: meta, loaded: MetaLoaded } = useSelector((state: any) => state.global.meta);
  const { loaded: queueLoaded } = useSelector((state: any) => state['otc-admin-merchant'].universal.queue);
  const { data: otcAdminMeta, loaded: otcAdminMetaLoaded } = useSelector((state: any) => state['otc-admin-merchant'].universal.otcAdminMetaSettings);
  const isChanged = useSelector((state: any) => state['otc-admin-merchant'].universal.systemConfigDidntSave);

  const validationField: any = Object.keys(formData);
  const [validations, setValidations] = React.useState<any>({
    'cny-default-amount': [
      Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(1),
    ],
    'limit-ratio': [
      Vaildator.required, Vaildator.isFloat,
      Vaildator.maxNumber(100), Vaildator.minNumber(0), Vaildator.decimalPointMaxLength(0),
    ],
  });

  function onInputChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;
    let validationsNew = { ...validations };

    setFormData({ ...formData, [name]: value });
    dispatch(UniversalActions.setSystemConfigDidntSave(true));

    if (validations[name]) {
      const validate = Vaildator.onValidate({ validations: validationsNew[name], name, value });
      const newErrorMessage: any = { ...errorMessage, ...validate.errorMessage };

      setErrorMessage(newErrorMessage);
    }
  }

  function onSubmit() {
    let validBol: any = true;
    let validationFieldList = [...validationField];
    let validationsNew = { ...validations };

    validationFieldList.forEach((key: string) => {
      if (validBol && validations[key]) {
        const validate = Vaildator.onValidate({ validations: validationsNew[key], name: key, value: String(formData[key]) });
        let newErrorMessage: any = { ...errorMessage, ...validate.errorMessage }
        setErrorMessage(newErrorMessage);
        validBol = validate.validBol;
      }
    });

    if (validBol) {
      let payload: any = {
        group: 'trader-account-tx',
        keys: {
          'cny-default-amount': parseFloat(formData['cny-default-amount']),
          'limit-ratio': parseFloat(formData['limit-ratio']) / 100,
        },
      };

      dispatch(UniversalActions.updateOtcAdminMetaSettingsRequest([payload]));
    }
  }

  useEffect(() => {
    if (queueLoaded) {
      dispatch(UniversalActions.getOtcAdminMetaSettingsRequest());
      dispatch(UniversalActions.setSystemConfigDidntSave(false));
    }
  }, [queueLoaded]);

  useEffect(() => {
    const traderAccountTxInfo = _.find(otcAdminMeta, (data) => (data.group === 'trader-account-tx'));

    if (!traderAccountTxInfo) { return; }

    setFormData({
      'cny-default-amount': traderAccountTxInfo.keys['cny-default-amount'],
      'limit-ratio': (traderAccountTxInfo.keys['limit-ratio'] * 100),
    });
    setErrorMessage({});
  }, [otcAdminMetaLoaded]);

  return (<>
    <Box px={4} py={2} className={classes.titleArea}>
      <FormattedMessage id='action.otc-admin-merchant.merchant.systemConfig.tab.paymentMethod' />
    </Box>
    <Box px={4} py={2}>
      <Box className={classes.fieldArea}>
        <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.paymentMethod.choose' />：</Label>
        <CustomTextField
          select
          variant='outlined'
          name='exchangeRateCryptoCurrency'
          value={'银行卡'}
          onChange={onInputChange}
        >
          <MenuItem value='银行卡'>
            银行卡
          </MenuItem>
        </CustomTextField>
      </Box>
      <Box mt={2} className={classes.fieldArea}>
        <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.paymentMethod.currency' />：</Label>
        <CustomTextField
          select
          variant='outlined'
          name='exchangeRateCryptoCurrency'
          value={'CNY'}
          onChange={onInputChange}
        >
          <MenuItem value='CNY'>
            CNY
          </MenuItem>
        </CustomTextField>
      </Box>
      <Box mt={2} className={classes.fieldArea}>
        <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.paymentMethod.trader.bank.exposure.limit' />：</Label>
        <CustomTextField
          variant='outlined'
          name='cny-default-amount'
          value={formData['cny-default-amount']}
          onChange={onInputChange}
          InputProps={{
            endAdornment: (< InputAdornment position='end' >CNY</InputAdornment>),
          }}
          error={!!errorMessage['cny-default-amount']}
          helperText={errorMessage['cny-default-amount'] ? intl.formatMessage({ id: `${errorMessage['cny-default-amount']}` }, { minimum: 1 }) : ''}
        />
      </Box>
      <Box mt={2} className={classes.fieldArea}>
        <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.paymentMethod.trader.order.exposure.limit' />：</Label>
        <CustomTextField
          variant='outlined'
          name='limit-ratio'
          value={formData['limit-ratio']}
          onChange={onInputChange}
          InputProps={{
            endAdornment: (< InputAdornment position='end' >%</InputAdornment>),
          }}
          error={!!errorMessage['limit-ratio']}
          helperText={errorMessage['limit-ratio'] ? intl.formatMessage({ id: `${errorMessage['limit-ratio']}` }, { minimum: 0, maximum: 100 }) : ''}
        />
      </Box>
    </Box>
    <Box pt={1} pb={3} px={3} textAlign='right'>
      <Button variant='contained' disableElevation={true} onClick={onSubmit} disabled={!isChanged}
        className={classes.saveBtn}
      >
        <FormattedMessage id='action.save' />
      </Button>
    </Box>
    {(!otcAdminMetaLoaded || !queueLoaded) && <LinearProgress />}
  </>)
}

export default injectIntl(PaymentMethod)
