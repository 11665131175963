import actionsHelper from 'utils/actionsHelper';
const names:any = [
  'GET_OTC_ADMIN_META',
  'GET_OTC_ADMIN_META_SETTINGS',
  'UPDATE_OTC_ADMIN_META_SETTINGS',
];

const  createActions:any = actionsHelper(names);

export const SET_SYSTEM_CONFIG_TAB_INDEX: string = 'SET_SYSTEM_CONFIG_TAB_INDEX';
const setSystemConfigTabIndex = (payload: any) => ({
  type: SET_SYSTEM_CONFIG_TAB_INDEX,
  payload,
});

export const SET_SYSTEM_CONFIG_DIDNT_SAVE: string = 'SET_SYSTEM_CONFIG_DIDNT_SAVE';
const setSystemConfigDidntSave = (payload: any) => ({
  type: SET_SYSTEM_CONFIG_DIDNT_SAVE,
  payload,
});

export default {
  SET_SYSTEM_CONFIG_TAB_INDEX,
  setSystemConfigTabIndex,
  SET_SYSTEM_CONFIG_DIDNT_SAVE,
  setSystemConfigDidntSave,
  ...createActions.types,
  ...createActions.actions
}
