import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Exchange from './Exchange';
import Order from './Order';
import PaymentMethod from './PaymentMethod';
import Withdrawal from './Withdrawal';

import {
  Box,
  AppBar,
  Tab,
  Tabs,
  Button
} from '@material-ui/core';
import SystemConfigPrompt from './Components/SystemConfigPrompt';

import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import GlobalActions from 'stores/global/actions';
import UniversalActions from 'stores/otc-admin-merchant/universal/actions';
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabStyle: {
      '& button': {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px 8px 0 0',
        fontSize: '14px',
        opacity: '0.4',
        color: '#9b9b9b',
        boxSizing: 'border-box',
        marginRight: '4px',
        minWidth: '76px',
        minHeight: '36px'
      },
      '& .Mui-selected': {
        backgroundColor: '#ffffff',
        color: theme.palette.info.dark,
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      }
    },
    cardContent: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, .1)',
      borderRadius: '0 4px 4px 4px',
    },
    selectStyle: {
      backgroundColor: 'transparent',
      color: '#999999',
      boxShadow: 'none',
      height: '36px'
    },
    notSaveWarningContent: {
      borderTop: '1px solid #efefef',
      padding: '24px 24px 0',
      color: theme.palette.secondary.main,
      fontSize: '1rem',
    },
    notSaveWarningButtons: {
      paddingTop: 40,
      paddingBottom: 24,
      textAlign: 'right',

      '& > button': {
        padding: '8px 46px',
      },
    },
    saveBtn: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
    },
  })
);

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.cardContent}
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const Index = (props: any) => {
  const {
    intl,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const notSaveWarning = (nextTab:number) => {
    dispatch(GlobalActions.toogleDialog({
      visible: true,
      title: 'title.otc-admin-merchant.system.didnt.save',
      variant: '#fff',
      titleTextColor: '#2e2e2e',
      component: (<Box className={classes.notSaveWarningContent}>
        <FormattedMessage id='description.otc-admin-merchant.system.didnt.save' />
        <Box className={classes.notSaveWarningButtons}>
          <Button style={{ marginRight: 16 }} onClick={() => {
            dispatch(GlobalActions.toogleDialog({ visible: false }));
          }}><FormattedMessage id='action.cancel' /></Button>
          <Button className={classes.saveBtn} onClick={() => {
            dispatch(GlobalActions.toogleDialog({ visible: false }));
            dispatch(UniversalActions.setSystemConfigTabIndex({ index: nextTab }));
          }}><FormattedMessage id='action.ok' /></Button>
        </Box>
      </Box>),
      contentHasPadding: false,
    }));
  }

  const handleChangeTab = (event: React.ChangeEvent<Object>, newValue: number) => {
    if (!isChanged) {
      dispatch(UniversalActions.setSystemConfigTabIndex({ index: newValue }));
    } else {
      notSaveWarning(newValue);
    }
  };

  const pageIndex = useSelector((state: any) => state['otc-admin-merchant'].universal.systemConfigTabIndex);
  const isChanged = useSelector((state: any) => state['otc-admin-merchant'].universal.systemConfigDidntSave);

  return (<>
    <SystemConfigPrompt isTrigger={isChanged} />
    <AppBar position='static' className={classes.selectStyle}>
      <Tabs value={pageIndex} onChange={handleChangeTab} className={classes.tabStyle}>
        <Tab disableRipple={true} label={intl.formatMessage({ id: 'action.otc-admin-merchant.merchant.systemConfig.tab.exchangeRate' })} />
        <Tab disableRipple={true} label={intl.formatMessage({ id: 'action.otc-admin-merchant.merchant.systemConfig.tab.order' })} />
        <Tab disableRipple={true} label={intl.formatMessage({ id: 'action.otc-admin-merchant.merchant.systemConfig.tab.paymentMethod' })} />
        <Tab disableRipple={true} label={intl.formatMessage({ id: 'action.otc-admin-merchant.merchant.systemConfig.tab.withdrawal' })} />
      </Tabs>
    </AppBar>
    <TabPanel value={pageIndex} index={0}>
      <Exchange />
    </TabPanel>
    <TabPanel value={pageIndex} index={1}>
      <Order />
    </TabPanel>
    <TabPanel value={pageIndex} index={2}>
      <PaymentMethod />
    </TabPanel>
    <TabPanel value={pageIndex} index={3}>
      <Withdrawal />
    </TabPanel>
  </>)
}

export default injectIntl(Index);
