export default [
  {
    path: '/user',
    component: 'layouts/full',
    isRoot: true,
    routes: [
      {
        title: '登入',
        path: '/user/login',
        component: 'user/Login',
      },
      {
        title: '忘記密碼',
        path: '/user/resetpassword',
        component: 'user/ResetPassword',
      },
    ],
  },
  {
    path: '/',
    component: 'layouts/drawerV2',
    redirect: {
      default: '/account/settings',
      enterprise: '/account/settings',
      investor: '/account/settings',
    },
    routes: [
      {
        title: 'title.404.notfound',
        Icon: 'components/Icons/IconBlockchain',
        path: '/404',
        component: '404',
        order: 999,
        routes: [],
        navList: false,
        breadcrumb: false,
      },
      {
        title: 'title.system.setting',
        Icon: 'IconSystemSetting',
        path: '/account',
        redirect: '/account/settings',
        breadcrumbLink: false,
        order: 999,
        routes: [
          {
            title: 'title.account.settings',
            path: '/account/settings',
            component: 'PluginsPage',
            plugins: [
              {
                component: 'account/ProfileInfo',
              },
              {
                component: 'account/Security',
              },
            ],
          },
          {
            title: 'title.admin.setting',
            path: '/account/adminsetting',
            component: 'account/adminSetting/Index',
            navList: true,
            routes: [],
          },
          {
            title: 'title.role.setting',
            path: '/account/roles',
            component: 'account/roles/Index',
            navList: true,
            routes: [],
          },
          {
            title: 'title.otc-admin-merchant.system.setting',
            path: '/account/systemsetting',
            component: 'PluginsPage',
            plugins: [
              {
                component: 'otc-admin-merchant/systemSetting/Index',
              },
            ],
          },
        ],
      },
      {
        title: 'title.otc-admin',
        Icon: 'IconTrader',
        path: '/otc-admin',
        authorize: ['default', 'investor', 'enterprise'],
        breadcrumbLink: false,
        order: 1,
        routes: [
          {
            title: 'title.otc-admin.trader.list',
            path: '/otc-admin/trader/list',
            component: 'otc-admin/trader/traderList/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [
              {
                title: 'title.otc-admin.trader.detail',
                path: '/otc-admin/trader/list/:traderId/detail',
                component: 'otc-admin/trader/traderList/Detail',
                routes: [
                  {
                    title: 'title.otc-admin.trader.bankcard.detail',
                    path: '/otc-admin/trader/list/:traderId/detail/bankcard/detail',
                    component: 'otc-admin/trader/traderList/BankCardDetail',
                    routes: [],
                  },
                ],
              },
              {
                title: 'title.otc-admin.trader.ledger',
                path: '/otc-admin/trader/list/:traderId/ledger',
                component: 'otc-admin/trader/traderList/Ledger',
                routes: []
              }
            ],
          },
          {
            title: 'title.otc-admin.trader.order.list',
            path: '/otc-admin/trader/order/list',
            component: 'otc-admin/trader/orderList/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [],
          },
          {
            title: 'title.otc-admin.trader.transfer',
            path: '/otc-admin/trader/transfer/list',
            component: 'otc-admin/trader/transferList/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [],
          }
        ],
      },
      {
        title: 'title.otc-admin-merchant',
        Icon: 'IconMerchant',
        path: '/otc-admin-merchant',
        authorize: ['investor', 'enterprise'],
        breadcrumbLink: false,
        order: 2,
        routes: [
          {
            title: 'title.otc-admin-merchant.merchant.channel.list',
            path: '/otc-admin-merchant/merchant/channel',
            component: 'otc-admin-merchant/merchant/channel/Index',
            navList: true,
            routes: [
              {
                title: 'title.otc-admin-merchant.merchant.detail',
                path: '/otc-admin-merchant/merchant/channel/:channelId/detail',
                component: 'otc-admin-merchant/merchant/channel/Detail',
              },
              {
                title: 'title.otc-admin-merchant.merchant.ledger',
                path: '/otc-admin-merchant/merchant/channel/ledger',
                component: 'otc-admin-merchant/merchant/channel/Ledger'
              }
            ],
          },
          {
            title: 'title.otc-admin-merchant.merchant.transfer',
            path: '/otc-admin-merchant/merchant/transfer',
            component: 'otc-admin-merchant/merchant/transferList/Index',
            navList: true,
            routes: [
              {
                title: 'title.otc-admin-merchant.create.withdrawals',
                path: '/otc-admin-merchant/merchant/transfer/create',
                component: 'otc-admin-merchant/merchant/withdrawals/Create',
              },
            ],
          },
          {
            title: 'title.otc-admin-merchant.settlement.list',
            path: '/otc-admin-merchant/merchant/settlement/list',
            component: 'otc-admin-merchant/merchant/settlement/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [
              {
                title: 'title.otc-admin-merchant.edit.settlement',
                path: '/otc-admin-merchant/merchant/settlement/list/:settlementId/edit',
                component: 'otc-admin-merchant/merchant/settlement/Edit',
              },
              {
                title: 'title.otc-admin-merchant.view.settlement',
                path: '/otc-admin-merchant/merchant/settlement/list/:settlementId/view',
                component: 'otc-admin-merchant/merchant/settlement/View',
              },
            ],
          },
        ],
      },
      {
        title: 'title.otc-admin-agent',
        Icon: 'badge-outlined',
        path: '/otc-admin-agent',
        authorize: [
          'investor',
          'enterprise'
        ],
        breadcrumbLink: false,
        order: 3,
        routes: [
          {
            title: 'title.otc-admin-agent.setting',
            path: '/otc-admin-agent/setting',
            component: 'otc-admin-agent/agent/setting/Index',
            navList: true,
            routes: [
              {
                title: 'title.otc-admin-agent.setting',
                path: '/otc-admin-agent/setting/:agentId',
                component: 'otc-admin-agent/agent/setting/Detail',
                breadcrumb: false,
                routes: [
                  {
                    title: 'title.otc-admin-agent.trader.member.detail',
                    path: '/otc-admin-agent/setting/:agentId/group/:groupId',
                    component: 'otc-admin-agent/agent/setting/trader/GroupList',
                    breadcrumb: false
                  },
                  {
                    title: 'title.otc-admin-agent.trader.dedicated.channel',
                    path: '/otc-admin-agent/setting/:agentId/channel/:groupId',
                    component: 'otc-admin-agent/agent/setting/trader/DedicatedChannel',
                    breadcrumb: false
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
  },
];
