import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import clsx from 'clsx';
import _ from 'lodash';

import {
  Box,
  Typography,
  IconButton,
  Paper,
  CardActions,
  CardContent,
  Collapse,
  LinearProgress,
  Button,
  TextField,
  InputAdornment
} from '@material-ui/core';

import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import UniversalActions from 'stores/otc-admin-merchant/universal/actions';
import Vaildator from 'utils/validator';


const CustomTextField = withStyles((theme: Theme) => ({
  root: {
    width: 287,

    '& .MuiFormHelperText-contained': {
      marginLeft: 0
    }
  },
  input: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}))((props: any) => {
  const { classes, ...params } = props;

  return (<TextField
    variant='outlined' classes={{ root: classes.root }} {...params} InputProps={{
      ...props.InputProps,
      classes: {
        input: classes.input,
      },
    }}
  />)
});

const Label = withStyles((theme: Theme) => ({
  root: {
    minWidth: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.palette.secondary.main,
  },
}))(Box);

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    titleArea: {
      borderBottom: '1px solid #efefef',
      color: '#2e2e2e',
      fontWeight: 500,
      fontSize: '1rem'
    },
    fieldArea: {
      display: 'flex',
      alignItems: 'center',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      padding: '0'
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    saveBtn: {
      paddingLeft: 46,
      paddingRight: 46,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
    },
  })
));

const Order = (props: any) => {
  const {
    intl,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expandedUniversal, setExpandedUniversal] = React.useState(false);
  const [expandedPayment, setExpandedPayment] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>({
    'client-max-daily-cancellations': { },
    'trader-order-threshold': { },
    'order-buy': { },
    'order-sell': { },
    'order-daily-tx-limit': { },
    'order-commission': { },
  });

  const { data: meta, loaded: MetaLoaded } = useSelector((state: any) => state.global.meta);
  const { loaded: queueLoaded } = useSelector((state: any) => state['otc-admin-merchant'].universal.queue);
  const { data: otcAdminMeta, loaded: otcAdminMetaLoaded } = useSelector((state: any) => state['otc-admin-merchant'].universal.otcAdminMetaSettings);
  const isChanged = useSelector((state: any) => state['otc-admin-merchant'].universal.systemConfigDidntSave);

  const fields = [
    {
      type: 'universal',
      label: 'label.otc-admin-merchant.system.order.meanwhile',
      group: 'trader-order-threshold', keyName: 'ongoing',
      vaildations: [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(1)],
      errorMsgOptions: { minimum: 1},
    },
    {
      type: 'universal',
      label: 'label.otc-admin-merchant.system.order.maximum.disputing',
      group: 'trader-order-threshold', keyName: 'disputing',
      vaildations: [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(1)],
      errorMsgOptions: { minimum: 1 },
    },
    {
      type: 'universal',
      label: 'label.otc-admin-merchant.system.order.comsumer.one.day.canceled',
      group: 'client-max-daily-cancellations', keyName: 'number',
      vaildations: [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(1)],
      errorMsgOptions: { minimum: 1 },
    },
    {
      type: 'universal',
      label: 'label.otc-admin-merchant.system.order.transfer.limit',
      group: 'order-daily-tx-limit', keyName: 'cny',
      memo: intl.formatMessage({ id: 'description.system.order.transfer.limit' }),
      endAdornment: `${meta.exchangeRates['usdt-cny'].currency}/${intl.formatMessage({ id: 'unit.each.day' })}`,
      vaildations: [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(1)],
      errorMsgOptions: { minimum: 1 },
    },
    {
      type: 'universal',
      label: 'label.otc-admin-merchant.system.order.trader.buy.commission.limit',
      group: 'order-commission', keyName: 'max-buy-ratio',
      endAdornment: '%', subLabel: 'label.otc-admin-merchant.system.order.digital.currency',
      vaildations: [
        Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01), Vaildator.maxNumber(99.99),
        Vaildator.decimalPointMaxLength(2),
      ],
      errorMsgOptions: { minimum: 0.01, maximum: 99.99 },
    },
    {
      type: 'universal',
      label: 'label.otc-admin-merchant.system.order.trader.sell.commission.limit',
      group: 'order-commission', keyName: 'max-sell-ratio',
      endAdornment: '%', subLabel: 'label.otc-admin-merchant.system.order.digital.currency',
      vaildations: [
        Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01), Vaildator.maxNumber(99.99),
        Vaildator.decimalPointMaxLength(2),
      ],
      errorMsgOptions: { minimum: 0.01, maximum: 99.99 },
    },
    {
      type: 'universal',
      label: 'label.otc-admin-merchant.system.order.trader.default.buy.commission',
      group: 'order-commission', keyName: 'buy-ratio',
      endAdornment: '%', subLabel: 'label.otc-admin-merchant.system.order.digital.currency',
      vaildations: [
        Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01),
        Vaildator.maxNumber(getValueOnFormData('order-commission', 'max-buy-ratio')),
        Vaildator.decimalPointMaxLength(2),
      ],
      errorMsgOptions: { minimum: 0.01, maximum: getValueOnFormData('order-commission', 'max-buy-ratio') },
    },
    {
      type: 'universal',
      label: 'label.otc-admin-merchant.system.order.trader.default.sell.commission',
      group: 'order-commission', keyName: 'sell-ratio',
      endAdornment: '%', subLabel: 'label.otc-admin-merchant.system.order.digital.currency',
      vaildations: [
        Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01),
        Vaildator.maxNumber(getValueOnFormData('order-commission', 'max-sell-ratio')),
        Vaildator.decimalPointMaxLength(2),
      ],
      errorMsgOptions: { minimum: 0.01, maximum: getValueOnFormData('order-commission', 'max-sell-ratio') },
    },
    {
      type: 'universal',
      label: 'label.otc-admin-merchant.system.order.auto.checked.time',
      group: 'order-sell', keyName: 'auto-confirm-minute',
      endAdornment: intl.formatMessage({ id: 'unit.minute' }),
      vaildations: [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(1)],
      errorMsgOptions: { minimum: 1 },
    },
    {
      type: 'payment-method-buy',
      label: 'label.otc-admin-merchant.system.payment.minimum.buy.amount',
      group: 'order-buy', keyName: 'cny-min-amount',
      endAdornment: meta.exchangeRates['usdt-cny'].currency,
      vaildations: [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01), Vaildator.decimalPointMaxLength(2)],
      errorMsgOptions: { minimum: 0.01 },
    },
    {
      type: 'payment-method-buy',
      label: 'label.otc-admin-merchant.system.payment.maximum.amount',
      group: 'order-buy', keyName: 'cny-max-amount',
      endAdornment: meta.exchangeRates['usdt-cny'].currency,
      vaildations: [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01), Vaildator.decimalPointMaxLength(2)],
      errorMsgOptions: { minimum: 0.01 },
    },
    {
      type: 'payment-method-sell',
      label: 'label.otc-admin-merchant.system.payment.minimum.sell.amount',
      group: 'order-sell', keyName: 'cny-min-amount',
      endAdornment: meta.exchangeRates['usdt-cny'].currency,
      vaildations: [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01), Vaildator.decimalPointMaxLength(2)],
      errorMsgOptions: { minimum: 0.01 },
    },
    {
      type: 'payment-method-sell',
      label: 'label.otc-admin-merchant.system.payment.maximum.amount',
      group: 'order-sell', keyName: 'cny-max-amount',
      endAdornment: meta.exchangeRates['usdt-cny'].currency,
      vaildations: [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01), Vaildator.decimalPointMaxLength(2)],
      errorMsgOptions: { minimum: 0.01 },
    },
  ];

  const validationField: any = _.map(fields, (field) => (`${field.group}.${field.keyName}`));
  const [validations, setValidations] = React.useState<any>(
    _.reduce(fields, (result, field) => {
      result[`${field.group}.${field.keyName}`] = field.vaildations;
      return result;
    }, {} as any)
  );

  const handleExpandUniversalClick = () => {
    setExpandedUniversal(!expandedUniversal);
  };
  const handleExpandPaymentClick = () => {
    setExpandedPayment(!expandedPayment);
  };

  const updateOrderMaxRatioValidtions = (newValidations:object) => {
    setValidations({
      ...validations,
      ...newValidations,
    });
  }

  function getValueOnFormData(group:string, key:string):number {
    return parseFloat(formData[group][key] || 0);
  }

  function onInputChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;
    let validationsNew = { ...validations };
    let [group, key] = name.split('.');

    setFormData({ ...formData, [group]: { ...formData[group], [key]: value} });
    dispatch(UniversalActions.setSystemConfigDidntSave(true));

    if (group === 'order-commission') {
      if (key === 'max-buy-ratio') {
        updateOrderMaxRatioValidtions({
          'order-commission.buy-ratio': [
            Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01),
            Vaildator.maxNumber(value), Vaildator.decimalPointMaxLength(2),
          ],
        });

      } else if (key === 'max-sell-ratio') {
        updateOrderMaxRatioValidtions({
          'order-commission.sell-ratio': [
            Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01),
            Vaildator.maxNumber(value), Vaildator.decimalPointMaxLength(2),
          ],
        });
      }
    }

    if (validations[name]) {
      const validate = Vaildator.onValidate({ validations: validationsNew[name], name, value });
      let newErrorMessage: any = { ...errorMessage, ...validate.errorMessage };

      if (group === 'order-commission' && (key === 'max-buy-ratio' || key === 'max-sell-ratio')) {
        const relatedField = key.replace('max-', '');

        if (parseFloat(value) >= parseFloat(formData[group][relatedField]) &&
          newErrorMessage[`${group}.${relatedField}`] === 'error.validator.maximum.number'
        ) {

          newErrorMessage[`${group}.${relatedField}`] = '';
        }
      }

      setErrorMessage(newErrorMessage);
    }
  }

  function onSubmit() {
    let validBol: any = true;
    let validationFieldList = [...validationField];
    let validationsNew = { ...validations };

    validationFieldList.forEach((valueIndex: string) => {
      const [group, key] = valueIndex.split('.');
      if (validBol && validations[valueIndex]) {
        const validate = Vaildator.onValidate({
          validations: validationsNew[valueIndex],
          [valueIndex]: valueIndex, value: String(formData[group][key]),
        });

        let newErrorMessage: any = { ...errorMessage, ...validate.errorMessage }
        setErrorMessage(newErrorMessage);
        validBol = validate.validBol;
      }
    });

    if (validBol) {
      let payload: Array<object> = _.map(formData, (data: object, index: string): object => {
        if (index === 'order-commission') {
          let newKeys:any = {};
          _.forEach(data, (value: number|string, index:string) => {
            newKeys[index] = (typeof value === 'string'?parseFloat(value):value) / 100;
          });

          return { group: index, keys: newKeys};
        }

        return { group: index, keys: { ...data } };
      });

      dispatch(UniversalActions.updateOtcAdminMetaSettingsRequest(payload));
    }
  }

  useEffect(() => {
    if (true) {
      dispatch(UniversalActions.getOtcAdminMetaSettingsRequest());
      dispatch(UniversalActions.setSystemConfigDidntSave(false));
    }
  }, []);

  useEffect(() => {
    if (otcAdminMetaLoaded) {
      const traderorderthresholdInfo = _.find(otcAdminMeta, (data) => (data.group === 'trader-order-threshold'));
      const clientMaxDailyCancellationsInfo = _.find(otcAdminMeta, (data) => (
        data.group === 'client-max-daily-cancellations'
      ));
      const orderBuyInfo = _.find(otcAdminMeta, (data) => (data.group === 'order-buy'));
      const orderSellInfo = _.find(otcAdminMeta, (data) => (data.group === 'order-sell'));
      const orderDailyTxLimitInfo = _.find(otcAdminMeta, (data) => (data.group === 'order-daily-tx-limit'));
      const orderCommissionInfo = _.find(otcAdminMeta, (data) => (data.group === 'order-commission'));

      setFormData({
        'trader-order-threshold': { ...traderorderthresholdInfo.keys },
        'client-max-daily-cancellations': { ...clientMaxDailyCancellationsInfo.keys },
        'order-buy': { ...orderBuyInfo.keys },
        'order-sell': { ...orderSellInfo.keys },
        'order-daily-tx-limit': { ...orderDailyTxLimitInfo.keys },
        'order-commission': _.reduce(orderCommissionInfo.keys, (result:any, value:number, index:string):object => {
          result[index] = value * 100;
          return result;
        }, {} as any),
      });

      updateOrderMaxRatioValidtions({
        'order-commission.buy-ratio': [
          Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01),
          Vaildator.maxNumber(orderCommissionInfo.keys['max-buy-ratio'] * 100),
        ],
        'order-commission.sell-ratio': [
          Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01),
          Vaildator.maxNumber(orderCommissionInfo.keys['max-sell-ratio'] * 100),
        ],
      });
    }
  }, [otcAdminMetaLoaded]);

  return (<>
    <Box px={4} py={2} className={classes.titleArea}>
      <FormattedMessage id='action.otc-admin-merchant.merchant.systemConfig.tab.order' />
    </Box>
    <Paper elevation={0}>
      <CardActions disableSpacing style={{ backgroundColor: '#f2fbff', height: '36px', padding: '8px 32px' }}>
        <Box display="flex" alignItems="center">
          <Typography component="h3" variant="body2" style={{ marginRight: '8px', fontWeight: 'bold', color: theme.palette.secondary.main }}>{intl.formatMessage({ id: 'label.otc-admin-merchant.system.order.title.universal' })}</Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expandedUniversal,
            })}
            onClick={handleExpandUniversalClick}
            aria-expanded={expandedUniversal}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
      </CardActions>
      <Collapse in={expandedUniversal} timeout="auto" unmountOnExit>
        <CardContent style={{ padding: '16px 32px' }}>
          {fields.filter((field) => (field.type === 'universal')).map((field, index) => (
            <Box className={classes.fieldArea} key={index} mt={index > 0?2:0}>
              <Label mr={1}>
                <Box>
                  <FormattedMessage id={field.label} />
                  {field.subLabel && <Box textAlign='right'>(<FormattedMessage id={field.subLabel} />)</Box>}
                </Box>
                ：
              </Label>
              <CustomTextField
                variant='outlined'
                name={`${field.group}.${field.keyName}`}
                value={formData[field.group] && formData[field.group][field.keyName]}
                onChange={onInputChange}
                InputProps={{
                  endAdornment: (field.endAdornment &&
                    <InputAdornment position='end'>{field.endAdornment}</InputAdornment>
                  ),
                }}
                error={!!errorMessage[`${field.group}.${field.keyName}`]}
                helperText={errorMessage[`${field.group}.${field.keyName}`] ? intl.formatMessage({ id: `${errorMessage[`${field.group}.${field.keyName}`]}` }, { ...field.errorMsgOptions }) : (field.memo ? field.memo : '' )}
              />
            </Box>
          ))}
        </CardContent>
      </Collapse>

      <CardActions disableSpacing style={{ backgroundColor: '#f2fbff', height: '36px', padding: '8px 32px', marginTop: 8 }}>
        <Box display="flex" alignItems="center">
          <Typography component="h3" variant="body2" style={{ marginRight: '8px', fontWeight: 'bold', color: theme.palette.secondary.main }}>{intl.formatMessage({ id: 'label.otc-admin-merchant.system.order.title.payment' })}</Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expandedPayment,
            })}
            onClick={handleExpandPaymentClick}
            aria-expanded={expandedPayment}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
      </CardActions>
      <Collapse in={expandedPayment} timeout="auto" unmountOnExit>
        <CardContent style={{ padding: '24px 32px 0' }}>
          <Typography component="h3" variant="body2" style={{ marginRight: '8px', fontWeight: 'bold', color: theme.palette.secondary.main }}>{intl.formatMessage({ id: 'caption.otc-admin-merchant.merchant.system.buy.settings' })}</Typography>
          <Box color={theme.palette.secondary.main} m='16px 42px 24px' display='flex' alignItems='center'>
            {fields.filter((field) => (field.type === 'payment-method-buy')).map((field, index) => (
              <React.Fragment key={index}>
                <FormattedMessage id={field.label} />：
                <CustomTextField
                  variant='outlined'
                  name={`${field.group}.${field.keyName}`}
                  value={formData[field.group] && formData[field.group][field.keyName]}
                  onChange={onInputChange}
                  InputProps={{
                    endAdornment: (field.endAdornment &&
                      <InputAdornment position='end'>{field.endAdornment}</InputAdornment>
                    ),
                  }}
                  style={{ marginRight: 24, width: 139 }}
                  error={!!errorMessage[`${field.group}.${field.keyName}`]}
                  helperText={errorMessage[`${field.group}.${field.keyName}`] ? intl.formatMessage({ id: `${errorMessage[`${field.group}.${field.keyName}`]}` }, { ...field.errorMsgOptions }) : (field.memo ? field.memo : '')}
                />
              </React.Fragment>
            ))}
          </Box>

          <Typography component="h3" variant="body2" style={{ marginRight: '8px', fontWeight: 'bold', color: theme.palette.secondary.main }}>{intl.formatMessage({ id: 'caption.otc-admin-merchant.merchant.system.sell.settings' })}</Typography>
          <Box color={theme.palette.secondary.main} m='16px 42px 0' display='flex' alignItems='center'>
            {fields.filter((field) => (field.type === 'payment-method-sell')).map((field, index) => (
              <React.Fragment key={index}>
                <FormattedMessage id={field.label} />：
                <CustomTextField
                  variant='outlined'
                  name={`${field.group}.${field.keyName}`}
                  value={formData[field.group] && formData[field.group][field.keyName]}
                  onChange={onInputChange}
                  InputProps={{
                    endAdornment: (field.endAdornment &&
                      <InputAdornment position='end'>{field.endAdornment}</InputAdornment>
                    ),
                  }}
                  style={{ marginRight: 24, width: 139 }}
                  error={!!errorMessage[`${field.group}.${field.keyName}`]}
                  helperText={errorMessage[`${field.group}.${field.keyName}`] ? intl.formatMessage({ id: `${errorMessage[`${field.group}.${field.keyName}`]}` }, { ...field.errorMsgOptions }) : (field.memo ? field.memo : '')}
                />
              </React.Fragment>
            ))}
          </Box>
        </CardContent>
      </Collapse>
    </Paper>
    <Box p={3} textAlign='right'>
      <Button variant='contained' disableElevation={true} onClick={onSubmit} disabled={!isChanged}
        className={classes.saveBtn}
      >
        <FormattedMessage id='action.save' />
      </Button>
    </Box>
    {(!MetaLoaded || !otcAdminMetaLoaded || !queueLoaded) && <LinearProgress />}
  </>)
}

export default injectIntl(Order)
