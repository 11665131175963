import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom';

import {
  Box,
  Button,
} from '@material-ui/core';

import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

import GlobalActions from 'stores/global/actions';

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    content: {
      borderTop: '1px solid #efefef',
      padding: '24px 24px 0',
      color: theme.palette.secondary.main,
      fontSize: '1rem',
    },
    buttons: {
      paddingTop: 40,
      paddingBottom: 24,
      textAlign: 'right',

      '& > button': {
        padding: '8px 46px',
      },
    },
    saveBtn: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
    },
  })
));

const DidntSaveDialog = (props: any) => {
  const {
    isTrigger
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isOnGoing, setIsOnGoing] = React.useState<boolean>(false);
  const [nextPathname, setNextPathname] = React.useState<string>('');

  const closeDialog = () => {
    dispatch(GlobalActions.toogleDialog({ visible: false }));
  }

  const onSuspendBehavior = () => {
    closeDialog();
  }

  const onGoingBehavior = () => {
    setIsOnGoing(true);
    closeDialog();
  }

  React.useEffect(() => {
    if (isOnGoing) {
      history.push(nextPathname);
    }
  }, [isOnGoing]);

  const handleBlockedNavigation = (nextLocation: any) => {
    setNextPathname(nextLocation.pathname);

    dispatch(GlobalActions.toogleDialog({
      visible: true,
      title: 'title.otc-admin-merchant.system.didnt.save',
      variant: '#fff',
      titleTextColor: '#2e2e2e',
      component: (<Box className={classes.content}>
        <FormattedMessage id='description.otc-admin-merchant.system.didnt.save' />
        <Box className={classes.buttons}>
          <Button style={{ marginRight: 16 }} onClick={onSuspendBehavior}><FormattedMessage id='action.cancel' /></Button>
          <Button className={classes.saveBtn} onClick={onGoingBehavior}><FormattedMessage id='action.ok' /></Button>
        </Box>
      </Box>),
      contentHasPadding: false,
    }));

    return !isTrigger;
  }

  return (<Prompt when={(isTrigger && !isOnGoing)} message={handleBlockedNavigation} />);
}

export default DidntSaveDialog;
