import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';

import {
  Box,
  TextField,
  MenuItem,
  Icon,
  Tooltip,
  InputAdornment,
  Button,
  Switch,
  Collapse,
  LinearProgress
} from '@material-ui/core';

import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import UniversalActions from 'stores/otc-admin-merchant/universal/actions';
import Vaildator from 'utils/validator';

const CustomTextField = withStyles((theme: Theme) => ({
  root: {
    width: 287,
  },
  input: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}))((props: any) => {
  const { classes, ...params } = props;

  return (<TextField
    variant='outlined' classes={{root: classes.root}} {...params} InputProps={{
      ...props.InputProps,
      classes: {
        input: classes.input,
      },
    }}
  />)
});

const Label = withStyles((theme: Theme) => ({
  root: {
    minWidth: 98,
    textAlign: 'right',
    color: theme.palette.secondary.main,
  },
}))(Box);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleArea: {
      borderBottom: '1px solid #efefef',
      color: '#2e2e2e',
      fontWeight: 500,
      fontSize: '1rem'
    },
    fieldArea: {
      display: 'flex',
      alignItems: 'center'
    },
    infoIcon: {
      color: theme.palette.primary.main,
      marginLeft: 8,
      fontSize: 16
    },
    saveBtn: {
      paddingLeft: 46,
      paddingRight: 46,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
    },
  })
);

const ExchangeRate = (props: any) => {
  const {
    intl,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [metaExchangeRateInfo, setMetaExchangeRateInfo] = React.useState<any>({});
  const [exchangeRateInfo, setExchangeRateInfo] = React.useState<any>({});
  const [exchangeRateType, setExchangeRateType] = React.useState<any>(1);
  const [exchangeRateUnit, setExchangeRateUnit] = React.useState<any>(1);
  const [openCustomMode, setOpenCustomMode] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>({
    'usdt-cny-buy-offset-ratio': null,
    'usdt-cny-sell-offset-ratio': null,
    'usdt-cny-type': null,
    'usdt-cny-buy-price': null,
    'usdt-cny-sell-price': null,
    'usdt-cny-buy-offset-price': null,
    'usdt-cny-sell-offset-price': null,
  });

  const {data: meta, loaded: MetaLoaded} = useSelector((state: any) => state.global.meta);
  const { loaded: queueLoaded } = useSelector((state: any) => state['otc-admin-merchant'].universal.queue);
  const { data: otcAdminMeta, loaded: otcAdminMetaLoaded } = useSelector((state: any) => state['otc-admin-merchant'].universal.otcAdminMetaSettings);
  const isChanged = useSelector((state: any) => state['otc-admin-merchant'].universal.systemConfigDidntSave);

  const validationField: any = Object.keys(formData);
  const [validations, setValidations] = React.useState<any>({});

  function getPreviewExchangeRate (type:string) {
    const thisExchangeRate = (exchangeRateInfo.keys && exchangeRateInfo.keys['usdt-cny-rate']);

    if (!thisExchangeRate) { return null; }
    if (!openCustomMode) {
      return (thisExchangeRate);
    }

    const previewExchangeRate = (exchangeRateUnit === 1 ?
      (thisExchangeRate + (thisExchangeRate * (formData[`usdt-cny-${type}-offset-ratio`]) / 100)) :
      (thisExchangeRate + parseFloat(formData[`usdt-cny-${type}-offset-price`]))
    );

    return (Math.floor(previewExchangeRate * 100) / 100);
  }

  function onInputChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;
    let validationsNew = { ...validations };

    setFormData({ ...formData, [name]: value });
    dispatch(UniversalActions.setSystemConfigDidntSave(true));

    if (validations[name]) {
      const validate = Vaildator.onValidate({ validations: validationsNew[name], name, value });
      const newErrorMessage: any = { ...errorMessage, ...validate.errorMessage };

      setErrorMessage(newErrorMessage);
    }
  }

  function onSubmit () {
    let validBol: any = true;
    let validationFieldList = [...validationField];
    let validationsNew = { ...validations };

    validationFieldList.forEach((key: string) => {
      if (validBol && validations[key]) {
        const validate = Vaildator.onValidate({ validations: validationsNew[key], name: key, value: String(formData[key]) });
        let newErrorMessage: any = { ...errorMessage, ...validate.errorMessage }
        setErrorMessage(newErrorMessage);
        validBol = validate.validBol;
      }
    });

    if (validBol) {
      const usdtCnyType:number = (exchangeRateType === 1 ? 1 : (!openCustomMode ? 2 : (exchangeRateUnit === 1? 3 : 4)));
      let payload:any = {
        group: 'exchange-rate',
        keys: {
          'usdt-cny-type': usdtCnyType,
        },
      };

      switch (usdtCnyType) {
        case 1:
          payload.keys['usdt-cny-buy-price'] = parseFloat(formData['usdt-cny-buy-price']);
          payload.keys['usdt-cny-sell-price'] = parseFloat(formData['usdt-cny-sell-price']);
          break;
        case 3:
          payload.keys['usdt-cny-buy-offset-ratio'] = parseFloat(formData['usdt-cny-buy-offset-ratio']) / 100;
          payload.keys['usdt-cny-sell-offset-ratio'] = parseFloat(formData['usdt-cny-sell-offset-ratio']) / 100;
          break;
        case 4:
          payload.keys['usdt-cny-buy-offset-price'] = parseFloat(formData['usdt-cny-buy-offset-price']);
          payload.keys['usdt-cny-sell-offset-price'] = parseFloat(formData['usdt-cny-sell-offset-price']);
          break;
      }

      dispatch(UniversalActions.updateOtcAdminMetaSettingsRequest([payload]));
    }
  }

  useEffect(() => {
    if (queueLoaded) {
      dispatch(UniversalActions.getOtcAdminMetaSettingsRequest());
      dispatch(UniversalActions.setSystemConfigDidntSave(false));
    }
  }, [queueLoaded]);

  useEffect(() => {
    const usdtCnyType: number = (exchangeRateType === 1 ? 1 : (!openCustomMode ? 2 : (exchangeRateUnit === 1 ? 3 : 4)));
    const vaildations = [
      Vaildator.required, Vaildator.isFloat, Vaildator.decimalPointMaxLength(2)
    ];

    switch (usdtCnyType) {
      case 1:
        setValidations({
          'usdt-cny-buy-price': [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01), Vaildator.decimalPointMaxLength(2)],
          'usdt-cny-sell-price': [Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0.01), Vaildator.decimalPointMaxLength(2)],
        });
        break;
      case 3:
        setValidations({
          'usdt-cny-buy-offset-ratio': vaildations,
          'usdt-cny-sell-offset-ratio': vaildations,
        });
        break;
      case 4:
        setValidations({
          'usdt-cny-buy-offset-price': vaildations,
          'usdt-cny-sell-offset-price': vaildations,
        });
        break;
      default:
        setValidations({});
    }

  }, [openCustomMode, exchangeRateType, exchangeRateUnit]);

  useEffect(() => {
    const currentExchangeRateInfo = _.find(otcAdminMeta, (data) => (data.group === 'exchange-rate'));

    if (!currentExchangeRateInfo) {return;}

    setFormData({
      ...formData,
      'usdt-cny-buy-offset-ratio': (currentExchangeRateInfo.keys['usdt-cny-buy-offset-ratio'] * 100),
      'usdt-cny-sell-offset-ratio': (currentExchangeRateInfo.keys['usdt-cny-sell-offset-ratio'] * 100),
      'usdt-cny-type': currentExchangeRateInfo.keys['usdt-cny-type'],
      'usdt-cny-buy-price': currentExchangeRateInfo.keys['usdt-cny-buy-price'],
      'usdt-cny-sell-price': currentExchangeRateInfo.keys['usdt-cny-sell-price'],
      'usdt-cny-buy-offset-price': currentExchangeRateInfo.keys['usdt-cny-buy-offset-price'],
      'usdt-cny-sell-offset-price': currentExchangeRateInfo.keys['usdt-cny-sell-offset-price'],
    });
    setErrorMessage({});
  }, [exchangeRateType]);

  useEffect(() => {
    if (!(otcAdminMetaLoaded && MetaLoaded)) {return;}

    const metaExchangeRateInfo = _.find((meta?.exchangeRates), (exchangeRate) => (
      `${exchangeRate.crypto}-${exchangeRate.currency}`.toLowerCase() === 'usdt-cny'
    ));

    if (metaExchangeRateInfo) {
      setMetaExchangeRateInfo(metaExchangeRateInfo);
    }

    const currentExchangeRateInfo = _.find(otcAdminMeta, (data) => (data.group === 'exchange-rate'));
    if (currentExchangeRateInfo) {
      setExchangeRateInfo(currentExchangeRateInfo);

      setExchangeRateType(currentExchangeRateInfo.keys['usdt-cny-type'] > 1?2:1);

      if (currentExchangeRateInfo.keys['usdt-cny-type'] > 2) {
        setExchangeRateUnit(currentExchangeRateInfo.keys['usdt-cny-type'] === 3 ? 1 : 2);
        setOpenCustomMode(true);
      }

      setFormData({
        ...formData,
        'usdt-cny-buy-offset-ratio': currentExchangeRateInfo.keys['usdt-cny-buy-offset-ratio'] * 100,
        'usdt-cny-sell-offset-ratio': currentExchangeRateInfo.keys['usdt-cny-sell-offset-ratio'] * 100,
        'usdt-cny-type': currentExchangeRateInfo.keys['usdt-cny-type'],
        'usdt-cny-buy-price': currentExchangeRateInfo.keys['usdt-cny-buy-price'],
        'usdt-cny-sell-price': currentExchangeRateInfo.keys['usdt-cny-sell-price'],
        'usdt-cny-buy-offset-price': currentExchangeRateInfo.keys['usdt-cny-buy-offset-price'],
        'usdt-cny-sell-offset-price': currentExchangeRateInfo.keys['usdt-cny-sell-offset-price'],
      });
    }
  }, [otcAdminMetaLoaded, MetaLoaded]);

  return (
    <>
      <Box px={4} py={2} className={classes.titleArea}>
        <FormattedMessage id='action.otc-admin-merchant.merchant.systemConfig.tab.exchangeRate' />
      </Box>
      <Box px={4} py={2}>
        <Box className={classes.fieldArea}>
          <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.trading.pair' />：</Label>
          <CustomTextField
            select
            variant='outlined'
            name='exchangeRateCryptoCurrency'
            value={'usdt-cny'}
            onChange={onInputChange}
          >
            {_.map((meta?.exchangeRates), (exchangeRate, index) => (
              <MenuItem value={`${exchangeRate.crypto}-${exchangeRate.currency}`.toLowerCase()} key={index}>
                {`${exchangeRate.crypto} (${meta?.cryptos[exchangeRate.crypto]?.version}) / ${exchangeRate.currency}`}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>
        <Box mt={2} className={classes.fieldArea}>
          <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.exchange.mode' />：</Label>
          <CustomTextField
            select
            variant='outlined'
            name='exchangeRateType'
            value={exchangeRateType}
            onChange={(e:any) => {
              setExchangeRateType(e.target.value);
              dispatch(UniversalActions.setSystemConfigDidntSave(true));
            }}
          >
            <MenuItem value={1}><FormattedMessage id='relation.system.exchangeRate.type.manual' /></MenuItem>
            <MenuItem value={2}><FormattedMessage id='relation.system.exchangeRate.type.thirdParty' /></MenuItem>
          </CustomTextField>
        </Box>
        {exchangeRateType === 2 && <Box mt={2} className={classes.fieldArea}>
          <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.custom.spread' />：</Label>
          <Switch
            checked={openCustomMode}
            onChange={(e:any) => {
              setOpenCustomMode( e.target.checked );
              dispatch(UniversalActions.setSystemConfigDidntSave(true));
            }}
            color="primary"
            name="openCustomMode"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Box>}
        <Box mt={2} className={classes.fieldArea}>
          <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.basic.exchange.rate' />：</Label>
          <Box display='flex' alignItems='center'>
            {exchangeRateInfo.keys && exchangeRateInfo.keys['usdt-cny-rate']}
            <Tooltip title={intl.formatMessage({ id: 'placeholder.system.basic.exchange.rate' })} placement='right'>
              <Icon className={classes.infoIcon}>info</Icon>
            </Tooltip>
          </Box>
        </Box>
        <Collapse in={(exchangeRateType === 2 && openCustomMode === true)}>
          <Box mt={2} className={classes.fieldArea}>
            <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.custom.spread.unit' />：</Label>
            <CustomTextField
              select
              variant='outlined'
              name='usdt-cny-type'
              value={exchangeRateUnit}
              onChange={(e:any) => {
                setExchangeRateUnit(e.target.value);
                dispatch(UniversalActions.setSystemConfigDidntSave(true));
              }}
            >
              <MenuItem value={1}>%</MenuItem>
              <MenuItem value={2}><FormattedMessage id='relation.system.exchangeRate.unit.type.currency' /></MenuItem>
            </CustomTextField>
          </Box>
          <Box mt={2} className={classes.fieldArea}>
            <Label mr={1}>
              <FormattedMessage id='label.otc-admin-merchant.system.buy.spread' />：
            </Label>
            {(() => {
              const fieldName = `usdt-cny-buy-offset-${exchangeRateUnit === 1 ? 'ratio' : 'price'}`;

              return (<CustomTextField
                name={fieldName}
                value={formData[fieldName]}
                onChange={onInputChange}
                InputProps={{
                  endAdornment: (< InputAdornment position='end' >
                    {(exchangeRateUnit === 1 ? '%' : metaExchangeRateInfo.currency)
                    }</InputAdornment>),
                }}
                error={!!errorMessage[fieldName]}
                helperText={errorMessage[fieldName] ? intl.formatMessage({ id: `${errorMessage[fieldName]}` }, { minimum: 0.01 }) : ''}
              />);
            })()}
          </Box>
          <Box mt={2} className={classes.fieldArea}>
            <Label mr={1}>
              <FormattedMessage id='label.otc-admin-merchant.system.sell.spread' />：
            </Label>
            {(() => {
              const fieldName = `usdt-cny-sell-offset-${exchangeRateUnit === 1 ? 'ratio' : 'price'}`;

              return (<CustomTextField
                name={fieldName}
                value={formData[fieldName]}
                onChange={onInputChange}
                InputProps={{
                  endAdornment: (<InputAdornment position='end'>
                    {(exchangeRateUnit === 1 ? '%' : metaExchangeRateInfo.currency)}
                  </InputAdornment>),
                }}
                error={!!errorMessage[fieldName]}
                helperText={errorMessage[fieldName] ? intl.formatMessage({ id: `${errorMessage[fieldName]}` }, { minimum: 0.01 }) : ''}
              />);
            })()}
          </Box>
        </Collapse>
        {(exchangeRateType === 1) && <>
          <Box mt={2} className={classes.fieldArea}>
            <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.buy.prices' />：</Label>
            <CustomTextField
              name='usdt-cny-buy-price'
              value={formData['usdt-cny-buy-price'] || ''}
              onChange={onInputChange}
              InputProps={{
                endAdornment: (<InputAdornment position='end'>
                  {formData['usdt-cny-type'] === 3 ? '' : metaExchangeRateInfo.currency}
                </InputAdornment>),
              }}
              error={!!errorMessage['usdt-cny-buy-price']}
              helperText={errorMessage['usdt-cny-buy-price'] ? intl.formatMessage({ id: `${errorMessage['usdt-cny-buy-price']}` }, { minimum: 0.01 }) : ''}
            />
          </Box>
          <Box mt={2} className={classes.fieldArea}>
            <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.sell.prcies' />：</Label>
            <CustomTextField
              name='usdt-cny-sell-price'
              value={formData['usdt-cny-sell-price'] || ''}
              onChange={onInputChange}
              InputProps={{
                endAdornment: (< InputAdornment position='end' >
                  { formData['usdt-cny-type'] === 3 ? '' : metaExchangeRateInfo.currency}
                </InputAdornment>),
              }}
              error={!!errorMessage['usdt-cny-sell-price']}
              helperText={errorMessage['usdt-cny-sell-price'] ? intl.formatMessage({ id: `${errorMessage['usdt-cny-sell-price']}` }, { minimum: 0.01 }) : ''}
            />
          </Box>
        </>}
        {exchangeRateType === 2 && <>
          <Box mt={2} className={classes.fieldArea}>
            <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.preview.buy.prices' />：</Label>
            {getPreviewExchangeRate('buy')}
            <Box ml='4px' component='span' color='#a2a2a2'>{metaExchangeRateInfo.currency}</Box>
          </Box>
          <Box mt={2} className={classes.fieldArea}>
            <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.preview.sell.prcies' />：</Label>
            {getPreviewExchangeRate('sell')}
            <Box ml='4px' component='span' color='#a2a2a2'>{metaExchangeRateInfo.currency}</Box>
          </Box>
        </>}

        {exchangeRateType === 2 && <Box mt={2} className={classes.fieldArea} style={{ alignItems: 'flex-start' }}>
          <Label mr={1}><FormattedMessage id='label.otc-admin-merchant.system.exchangeRate.reference' />：</Label>
          <Box>
            {
              exchangeRateInfo.keys && exchangeRateInfo.keys['usdt-cny-provider']
            }
            <Box fontSize='0.75rem' color={theme.palette.secondary.main}>
              (<FormattedMessage id='description.otc-admin-merchant.system.updateTime' />：
              <Box component='span'>{
                metaExchangeRateInfo?.updateTime &&
                Moment((metaExchangeRateInfo.updateTime)).format('YYYY/MM/DD HH:mm:ss')
              })</Box>
            </Box>
          </Box>
        </Box>}
      </Box>
      <Box pt={1} pb={3} px={3} textAlign='right'>
        <Button variant='contained' disableElevation={true} onClick={onSubmit} disabled={!isChanged}
          className={classes.saveBtn}
        >
          <FormattedMessage id='action.save' />
        </Button>
      </Box>
      {(!otcAdminMetaLoaded || !queueLoaded) && <LinearProgress />}
    </>
  )
}

export default injectIntl(ExchangeRate);
