import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';

import {
  Box,
  TextField,
  MenuItem,
  Typography,
  InputAdornment,
  Button,
  LinearProgress
} from '@material-ui/core';

import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import UniversalActions from 'stores/otc-admin-merchant/universal/actions';
import Vaildator from 'utils/validator';

const CustomTextField = withStyles((theme: Theme) => ({
  root: {
    width: 287,
  },
  input: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}))((props: any) => {
  const { classes, ...params } = props;

  return (<TextField
    variant='outlined' classes={{ root: classes.root }} {...params} InputProps={{
      ...props.InputProps,
      classes: {
        input: classes.input,
      },
    }}
  />)
});

const Label = withStyles((theme: Theme) => ({
  root: {
    minWidth: 140,
    textAlign: 'right',
    color: theme.palette.secondary.main,
    marginRight: 8,
  },
}))(Box);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleArea: {
      borderBottom: '1px solid #efefef',
      color: '#2e2e2e',
      fontWeight: 500,
      fontSize: '1rem'
    },
    fieldArea: {
      display: 'flex',
      alignItems: 'center',
    },
    saveBtn: {
      paddingLeft: 46,
      paddingRight: 46,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
    },
  })
);

const Withdrawal = (props: any) => {
  const {
    intl,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [exchangeRateUnit, setExchangeRateUnit] = React.useState<any>(1);
  const [errorMessage, setErrorMessage] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>({
    'merchant-withdraw': {},
    'trader-withdraw': {},
  });

  const { data: meta, loaded: MetaLoaded } = useSelector((state: any) => state.global.meta);
  const { loaded: queueLoaded } = useSelector((state: any) => state['otc-admin-merchant'].universal.queue);
  const { data: otcAdminMeta, loaded: otcAdminMetaLoaded } = useSelector((state: any) => state['otc-admin-merchant'].universal.otcAdminMetaSettings);
  const isChanged = useSelector((state: any) => state['otc-admin-merchant'].universal.systemConfigDidntSave);

  const [validations, setValidations] = React.useState<any>({
    'merchant-withdraw.usdt-min-amount': [
      Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0), Vaildator.decimalPointMaxLength(0),
    ],
    'merchant-withdraw.usdt-max-amount': [
      Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0), Vaildator.decimalPointMaxLength(0),
    ],
    'merchant-withdraw.usdt-fee': [
      Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0), Vaildator.decimalPointMaxLength(0),
    ],
    'merchant-withdraw.usdt-fee-ratio': [
      Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0), Vaildator.decimalPointMaxLength(2),
      Vaildator.maxNumber(99.99),
    ],
    'trader-withdraw.usdt-min-amount': [
      Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0), Vaildator.decimalPointMaxLength(0),
    ],
    'trader-withdraw.usdt-max-amount': [
      Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0), Vaildator.decimalPointMaxLength(0),
    ],
    'trader-withdraw.usdt-fee': [
      Vaildator.required, Vaildator.isFloat, Vaildator.minNumber(0), Vaildator.decimalPointMaxLength(0),
    ],
  });
  const validationField: any = Object.keys(validations);

  function onInputChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;
    let validationsNew = { ...validations };
    let [group, key] = name.split('.');

    setFormData({ ...formData, [group]: { ...formData[group], [key]: value } });
    dispatch(UniversalActions.setSystemConfigDidntSave(true));

    if (validations[name]) {
      const validate = Vaildator.onValidate({ validations: validationsNew[name], name, value });
      const newErrorMessage: any = { ...errorMessage, ...validate.errorMessage };

      setErrorMessage(newErrorMessage);
    }
  }

  function onSubmit() {
    let validBol: any = true;
    let validationFieldList = [...validationField];
    let validationsNew = { ...validations };

    validationFieldList.forEach((valueIndex: string) => {
      const [group, key] = valueIndex.split('.');

      if (validBol && validations[valueIndex]) {
        const validate = Vaildator.onValidate({
          validations: validationsNew[valueIndex],
          [valueIndex]: valueIndex, value: String(formData[group][key]),
        });

        let newErrorMessage: any = { ...errorMessage, ...validate.errorMessage }
        setErrorMessage(newErrorMessage);
        validBol = validate.validBol;
      }
    });

    if (validBol) {
      let payload: Array<object> = _.map(formData, (data: any, index: string): object => {
        if (index === 'merchant-withdraw') {
          return { group: index, keys: {
            ...data,
            'usdt-fee-ratio': (data['usdt-fee-ratio'] / 100),
            'fee-type': exchangeRateUnit,
          } };
        }
        return { group: index, keys: { ...data } };
      });

      dispatch(UniversalActions.updateOtcAdminMetaSettingsRequest(payload));
    }
  }

  useEffect(() => {
    if (queueLoaded) {
      dispatch(UniversalActions.getOtcAdminMetaSettingsRequest());
      dispatch(UniversalActions.setSystemConfigDidntSave(false));
    }
  }, [queueLoaded]);

  useEffect(() => {
    if (otcAdminMetaLoaded) {
      const merchantWithrawInfo = _.find(otcAdminMeta, (data) => (data.group === 'merchant-withdraw'));
      const traderWithrawInfo = _.find(otcAdminMeta, (data) => (data.group === 'trader-withdraw'));

      setFormData({
        'merchant-withdraw': {
          ...merchantWithrawInfo.keys,
          'usdt-fee-ratio': (merchantWithrawInfo.keys['usdt-fee-ratio'] * 100),
        },
        'trader-withdraw': { ...traderWithrawInfo.keys},
      });
      setExchangeRateUnit(merchantWithrawInfo.keys['fee-type']);
    }
  }, [otcAdminMetaLoaded]);

  useEffect(() => {
    if (otcAdminMetaLoaded) {
      const merchantWithrawInfo = _.find(otcAdminMeta, (data) => (data.group === 'merchant-withdraw'));

      if (exchangeRateUnit === 1) {
        setErrorMessage({
          ...errorMessage,
          'merchant-withdraw.usdt-fee-ratio': '',
        });
        setFormData({
          ...formData,
          'merchant-withdraw': {
            ...formData['merchant-withdraw'],
            'usdt-fee-ratio': (merchantWithrawInfo.keys['usdt-fee-ratio'] * 100),
          },
        });
      } else if (exchangeRateUnit === 2) {
        setErrorMessage({
          ...errorMessage,
          'merchant-withdraw.usdt-fee': '',
        });
        setFormData({
          ...formData,
          'merchant-withdraw': {
            ...formData['merchant-withdraw'],
            'usdt-fee': merchantWithrawInfo.keys['usdt-fee'],
          },
        });
      }
    }
  }, [exchangeRateUnit]);

  return (<>
    <Box px={4} py={2} className={classes.titleArea}>
      <FormattedMessage id='action.otc-admin-merchant.merchant.systemConfig.tab.withdrawal' />
    </Box>
    <Box px={4} py={2}>
      <Box className={classes.fieldArea}>
        <Label><FormattedMessage id='label.otc-admin-merchant.system.withdrawal.currency' />：</Label>
        <CustomTextField
          select
          variant='outlined'
          name=''
          value={'USDT'}
          onChange={onInputChange}
        >
          {_.map(meta?.cryptos, (crypto, index) => (
            <MenuItem key={index} value={index}>
              {`${index} (${crypto.version})`}
            </MenuItem>
          ))}
        </CustomTextField>
      </Box>
      <Box>
        <Typography component='h3' variant='body2' style={{ margin: '24px 0 16px', fontWeight: 'bold', color: theme.palette.secondary.main }}>
          <FormattedMessage id='label.otc-admin-merchant.system.withdrawal.merchant.settings' />
        </Typography>
        <Box color={theme.palette.secondary.main} m='16px 0' display='flex' alignItems='center'>
          <Label>
            <FormattedMessage id='label.otc-admin-merchant.system.withdrawal.merchant.amount' />：
          </Label>
          <CustomTextField
            variant='outlined'
            name='merchant-withdraw.usdt-min-amount'
            value={formData['merchant-withdraw']['usdt-min-amount']}
            onChange={onInputChange}
            style={{ marginRight: 24, width: 139 }}
            InputProps={{
              endAdornment: (<InputAdornment position='end'>USDT</InputAdornment>),
            }}
            error={!!errorMessage['merchant-withdraw.usdt-min-amount']}
            helperText={errorMessage['merchant-withdraw.usdt-min-amount'] ? intl.formatMessage({ id: `${errorMessage['merchant-withdraw.usdt-min-amount']}` }, { minimum: 0 }) : ''}
          >
          </CustomTextField>
          <Label style={{ minWidth: 'auto' }}>
            <FormattedMessage id='label.otc-admin-merchant.system.withdrawal.maximum.amount' />：
          </Label>
          <CustomTextField
            variant='outlined'
            name='merchant-withdraw.usdt-max-amount'
            value={formData['merchant-withdraw']['usdt-max-amount']}
            onChange={onInputChange}
            style={{ width: 139 }}
            InputProps={{
              endAdornment: (<InputAdornment position='end'>USDT</InputAdornment>),
            }}
            error={!!errorMessage['merchant-withdraw.usdt-max-amount']}
            helperText={errorMessage['merchant-withdraw.usdt-max-amount'] ? intl.formatMessage({ id: `${errorMessage['merchant-withdraw.usdt-max-amount']}` }, { minimum: 0 }) : ''}
          >
          </CustomTextField>
        </Box>
        <Box color={theme.palette.secondary.main} m='16px 0 24px' display='flex' alignItems='center'>
          <Label>
            <FormattedMessage id='label.otc-admin-merchant.system.withdrawal.fee' />：
          </Label>
          {exchangeRateUnit === 1 && <CustomTextField
            variant='outlined'
            name='merchant-withdraw.usdt-fee'
            value={formData['merchant-withdraw']['usdt-fee']}
            onChange={onInputChange}
            style={{ marginRight: 8, width: 224 }}
            error={!!errorMessage['merchant-withdraw.usdt-fee']}
            helperText={errorMessage['merchant-withdraw.usdt-fee'] ? intl.formatMessage({ id: `${errorMessage['merchant-withdraw.usdt-fee']}` }, { minimum: 0 }) : ''}
          />}
          {exchangeRateUnit === 2 && <CustomTextField
            variant='outlined'
            name='merchant-withdraw.usdt-fee-ratio'
            value={formData['merchant-withdraw']['usdt-fee-ratio']}
            onChange={onInputChange}
            style={{ marginRight: 8, width: 224 }}
            error={!!errorMessage['merchant-withdraw.usdt-fee-ratio']}
            helperText={errorMessage['merchant-withdraw.usdt-fee-ratio'] ? intl.formatMessage({ id: `${errorMessage['merchant-withdraw.usdt-fee-ratio']}` }, { minimum: 0.01 }) : ''}
          />}
          <CustomTextField
            select
            variant='outlined'
            name='merchant-withdraw.fee-type'
            value={exchangeRateUnit}
            onChange={(e: any) => {
              setExchangeRateUnit(e.target.value);
              dispatch(UniversalActions.setSystemConfigDidntSave(true));
            }}
            style={{width: 120}}
          >
            <MenuItem value={2}>%</MenuItem>
            <MenuItem value={1}>
              <FormattedMessage id='label.otc-admin-merchant.system.withdrawal.digital.currency' />
            </MenuItem>
          </CustomTextField>
        </Box>
      </Box>
      <Box>
        <Typography component='h3' variant='body2' style={{ margin: '24px 0 16px', fontWeight: 'bold', color: theme.palette.secondary.main }}>
          <FormattedMessage id='label.otc-admin-merchant.system.withdrawal.trader.settings' />
        </Typography>
        <Box color={theme.palette.secondary.main} m='16px 0' display='flex' alignItems='center'>
          <Label>
            <FormattedMessage id='label.otc-admin-merchant.system.withdrawal.trader.minimum.amount' />：
          </Label>
          <CustomTextField
            variant='outlined'
            name='trader-withdraw.usdt-min-amount'
            value={formData['trader-withdraw']['usdt-min-amount']}
            onChange={onInputChange}
            style={{ marginRight: 24, width: 139 }}
            InputProps={{
              endAdornment: (<InputAdornment position='end'>USDT</InputAdornment>),
            }}
            error={!!errorMessage['trader-withdraw.usdt-min-amount']}
            helperText={errorMessage['trader-withdraw.usdt-min-amount'] ? intl.formatMessage({ id: `${errorMessage['trader-withdraw.usdt-min-amount']}` }, { minimum: 0 }) : ''}
          >
          </CustomTextField>
          <Label style={{ minWidth: 'auto' }}>
            <FormattedMessage id='label.otc-admin-merchant.system.withdrawal.maximum.amount' />：
          </Label>
          <CustomTextField
            variant='outlined'
            name='trader-withdraw.usdt-max-amount'
            value={formData['trader-withdraw']['usdt-max-amount']}
            onChange={onInputChange}
            style={{ width: 139 }}
            InputProps={{
              endAdornment: (<InputAdornment position='end'>USDT</InputAdornment>),
            }}
            error={!!errorMessage['trader-withdraw.usdt-max-amount']}
            helperText={errorMessage['trader-withdraw.usdt-max-amount'] ? intl.formatMessage({ id: `${errorMessage['trader-withdraw.usdt-max-amount']}` }, { minimum: 0 }) : ''}
          >
          </CustomTextField>
        </Box>
        <Box color={theme.palette.secondary.main} m='16px 0 0' display='flex' alignItems='center'>
          <Label>
            <FormattedMessage id='label.otc-admin-merchant.system.withdrawal.fee' />：
          </Label>
          <CustomTextField
            variant='outlined'
            name='trader-withdraw.usdt-fee'
            value={formData['trader-withdraw']['usdt-fee']}
            onChange={onInputChange}
            InputProps={{
              endAdornment: (<InputAdornment position='end'>USDT</InputAdornment>),
            }}
            error={!!errorMessage['trader-withdraw.usdt-fee']}
            helperText={errorMessage['trader-withdraw.usdt-fee'] ? intl.formatMessage({ id: `${errorMessage['trader-withdraw.usdt-fee']}` }, { minimum: 0 }) : ''}
          >
          </CustomTextField>
        </Box>
      </Box>
    </Box>
    <Box pt={1} pb={3} px={3} textAlign='right'>
      <Button variant='contained' disableElevation={true} onClick={onSubmit} disabled={!isChanged}
        className={classes.saveBtn}
      >
        <FormattedMessage id='action.save' />
      </Button>
    </Box>
    {(!MetaLoaded || !otcAdminMetaLoaded || !queueLoaded) && <LinearProgress />}
  </>)
}

export default injectIntl(Withdrawal)
